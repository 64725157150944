import { defineStore } from "pinia";
import { ref, computed, watch } from "vue";
import { usePlayerStore } from "@/stores/player";
import { useRequesterStore } from "@/stores/requester";
import {useBackgroundFetch} from "@/composables/useBackgroundFetchApi";
import { useOfflineStore } from "@/stores/offline";
export const useUserStore = defineStore("user", () => {
  const storedApiUser = JSON.parse(localStorage.getItem("ekiAppUser"));
  const storedConfig = JSON.parse(localStorage.getItem("ekiAppUserConfig"));
  const storedActivePitch = JSON.parse(localStorage.getItem("ekiPitchActive"));

  const playerX = usePlayerStore();
  const requesterX = useRequesterStore();
  const { getAllDownloadedSongs } = useBackgroundFetch();
  let i18nLocale = import.meta.env.VITE_DEFAULT_LOCALE;

  //State
  const firebaseUser = ref(null);
  const apiUser = ref(storedApiUser ?? null);
  const availableUpdates = ref({
    allSongs: null,
    missingSongs: null,
    packages: [
      { benefits: null, name: null, price: null, purchaseButton: null },
    ],
  });
  const fcmToken = ref(null);
  const songsLicense = ref({
    package: 0,
    songs: [],
  });
  const isPitchActive = ref(storedActivePitch?.isPitchActive ?? false);
  const settings = ref({
    visual: {
      playerPosition: "Abajo",
      fullScreenActive: false,
      showQueue: false,
    },
    bgVideo: "original",
    functionallity: {
      showCalification: true,
      recordModeActive: false,
    },
    font: {
      fontTheme: "Estilo 1",
      fontStyle: "MAYÚSCULAS",
    },
    karaoke: {
      showAllLyric: false,
    },
    language: {
      default: i18nLocale,
    },
    //to merge with default config, preventing errors of undefined in future possible configs
    ...(storedConfig ?? {}),
  });
  const localSongs = ref([]);
  watch(settings.value, (newSettings) => {
    if (newSettings) {
      localStorage.setItem("ekiAppUserConfig", JSON.stringify(newSettings));
    }
  });

  watch(isPitchActive, (newVal) => {
    localStorage.setItem(
      "ekiPitchActive",
      JSON.stringify({ isPitchActive: newVal })
    );
    if (playerX.playing) {
      location.reload();
    }
  });

  watch(
    () => settings.value.functionallity.recordModeActive,
    (recordMode) => {
      if (recordMode) {
        playerX.accessMicrophone();
      }
    }
  );

  const requestFullScreen = async () => {
    let wakeLock;

    console.log("REQUESTING FULLSCREEN");

    try {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        try {
          //avoid turning off screen in fullscreen mode
          wakeLock = await navigator.wakeLock.request("screen");
        } catch (error) {
          wakeLock = null;
        }
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
        if (wakeLock) {
          wakeLock.release();
          wakeLock = null;
        }
      }
    } catch (e) {
      settings.value.visual.fullScreenActive =
        !settings.value.visual.fullScreenActive;
      console.log(e);
    }
  };

  document.onfullscreenchange = function () {
    settings.value.visual.fullScreenActive = !document.fullscreenElement
      ? false
      : true;
  };

  //Getters
  const isLoggedIn = computed(() => apiUser.value != null);
  const isSubscribed = computed(
    () => apiUser.value && apiUser.value.isSubscribed
  );
  const cryptoKey = computed(() => {
    return apiUser.value?.key ?? import.meta.env.VITE_CRIPTO_KEY;
  });
  const tokens = computed(() => {
    return {
      token: apiUser.value?.token ?? null,
      refreshToken: apiUser.value?.refreshToken ?? null,
    };
  });
  const themeStyle = computed(() => {
    switch (settings.value.font.fontTheme) {
      case "Estilo 1":
        return "color-1";

      case "Estilo 2":
        return "color-2";

      case "Estilo 3":
        return "color-3";

      default:
        return "color-1";
    }
  });
  const fontStyle = computed(() => {
    switch (settings.value.font.fontStyle) {
      case "MAYÚSCULAS":
        return "mayus";

      case "minúsculas":
        return "minus";

      default:
        return "mayus";
    }
  });

  //Actions
  function saveState() {
    const user = apiUser.value;
    if (user) {
      localStorage.setItem("ekiAppUser", JSON.stringify(user));
    } else {
      //clean localstorage
      localStorage.clear();
      //clean caches
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach((name) => caches.delete(name));
        });
      }
      caches.keys().then((names) => {
        names.forEach((name) => caches.delete(name));
      });
    }
  }

  function setApiUser(user) {
    apiUser.value = user;

    if (user) {
      const { license, updates } = user;
      if (license) {
        createLicenseSongsList(license.access);
      }
      if (updates) {
        console.log("userX: availableUpdates", updates);
        availableUpdates.value = updates;
      }
    }
    saveState();
  }
  function createLicenseSongsList(access) {
    access = JSON.parse(access);
    songsLicense.value.package =
      access.package !== "*" ? access.package : Number.POSITIVE_INFINITY; //max;
    songsLicense.value.songs = access.songs;
  }
  function updateApiUser(user, isSubscribed, license = null, updates = null) {
    apiUser.value.user = user;
    apiUser.value.isSubscribed = isSubscribed;
    if (license) {
      createLicenseSongsList(license.access);
    }
    if (updates) {
      console.log("userX: availableUpdates", updates);
      availableUpdates.value = updates;
    }
    saveState();
  }
  function setFirebaseUser(fbUser) {
    firebaseUser.value = fbUser;
  }

  function setTokens(tokens) {
    apiUser.value.token = tokens.token;
    apiUser.value.key = tokens.key;
    apiUser.value.refreshToken = tokens.refreshToken;
    saveState();
  }

  function setFcmToken(token) {
    fcmToken.value = token;
  }

  function setUserSettings(newSettings) {
    settings.value = {
      ...newSettings,
      ...{
        visual: {
          fullScreenActive: false,
        },
      },
    };
  }

  function switchShortQueue() {
    settings.value.visual.showQueue = !settings.value.visual.showQueue;
  }

  function switchShowScore() {
    settings.value.functionallity.showCalification =
      !settings.value.functionallity.showCalification;
  }

  function cleanConfig() {
    localStorage.removeItem("ekiAppUserConfig");
    settings.value = {
      visual: {
        playerPosition: "Abajo",
        fullScreenActive: false,
        showQueue: false,
      },
      functionallity: {
        showCalification: true,
        recordModeActive: false,
      },
      font: {
        fontTheme: "Estilo 1",
        fontStyle: "MAYÚSCULAS",
      },
      karaoke: {
        showAllLyric: false,
      },
      language: {
        default: i18nLocale,
      },
    };
  }

  async function me() {
    try {

      //TODO -> improve it
      if(!isLoggedIn.value) return;
    
      const profileResp = await requesterX.Get({
        route: "/me",
        withAuth: true
      });
      const { user, isSubscribed, license, updates } = profileResp.data;
      const { settings } = user;
      
      if (settings) {
        setUserSettings(settings);
      }
      updateApiUser(user, isSubscribed, license, updates)
    } catch (error) {
      console.log(error);
      
    }
  }

  async function getLocalSongs(){
    const songs = await getAllDownloadedSongs();
    localSongs.value = songs;
  }
  getLocalSongs();

  return {
    //State
    firebaseUser,
    apiUser,
    fcmToken,
    settings,
    availableUpdates,
    isPitchActive,
    //Getters
    isLoggedIn,
    isSubscribed,
    cryptoKey,
    tokens,
    themeStyle,
    fontStyle,
    songsLicense,
    //Actions
    setApiUser,
    setTokens,
    setFirebaseUser,
    setFcmToken,
    setUserSettings,
    switchShortQueue,
    switchShowScore,
    cleanConfig,
    requestFullScreen,
    updateApiUser,

    me,
    localSongs,
    getLocalSongs
  };
});
